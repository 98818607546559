import { useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";
import { Logo, Logout } from "@assets/icons";

import NavbarList from "@components/Navbar/NavbarList";
import { AdminContext } from "@admin/contexts/AdminContext";
import { useFetchHook } from "@hooks/useFetchHook";
import Loader from "@components/Loader";
import styles from "./RootAdmin.module.scss";
import { Outlet } from "react-router-dom/dist";
import { ADMIN_ROOT_ROUTES } from "@admin/router";
import { sendNotify } from "@components/GlobalNotify/GlobalNotify";
import { useTranslation } from "react-i18next";

const RootAdmin = ({ route }) => {
  const { t } = useTranslation();
  const menuItems = ADMIN_ROOT_ROUTES.filter((item) => item?.isMenu);
  const { logout } = useContext(AdminContext);
  const [attemptLogout, { isLoading }] = useFetchHook(logout);
  return (
    <div className="flex flex-row flex-1 h-screen">
      <div className={clsx("bg-main pb-8 pt-8", styles.nav)}>
        <Logo className="mb-4" />
        <div className={clsx("flex-1 w-full", styles.navbarList)}>
          <NavbarList navItems={menuItems} />
        </div>
        <div
          onClick={() => {
            attemptLogout();
            sendNotify(t("auth.logout.logoutSuccess"), "success");
          }}
          className={clsx(
            "flex items-center cursor-pointer hover:opacity-80 transition duration-75 pt-4",
            isLoading && "pointer-events-none"
          )}
        >
          <AnimatePresence mode="wait" initial={false}>
            <motion.div
              key={isLoading || "empty"}
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
            >
              {isLoading ? (
                <Loader stroke="var(--colors-silver)" height={35} width={35} />
              ) : (
                <Logout height={35} width={35} />
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      <div className={clsx("flex flex-1 p-12 bg-cararra", styles.viewLayout)}>
        <Outlet />
      </div>
    </div>
  );
};

export default RootAdmin;
